<template>
  <slide-in dir="up" duration="500">
    <button
      v-show="isVisible"
      type="button"
      x-on:click.prevent="supportOpen = true"
      class="fixed bottom-6 right-6 py-4 px-6 flex justify-center items-center uppercase tracking-widest border border-transparent text-xs leading-4 font-semibold rounded-full text-white bg-blue-300 transition-colors duration-300 ease-in-out hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
      @click="toggleSupport"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 mr-1"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd"
        />
      </svg>
      {{ $t("support.title") }}
    </button>
  </slide-in>
</template>

<script>
import SlideIn from "../transitions/SlideIn.vue"

export default {
  components: { SlideIn },
  props: {
    currentRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: true,
    }
  },
  methods: {
    toggleSupport() {
      if (this.$store.state.support.init) {
        this.$store.commit("support/setCurrentRoute", this.currentRoute)
        this.$store.dispatch("support/init")
      }
      this.$store.dispatch("settings/toggleSupport", true)
    },
  },
}
</script>
