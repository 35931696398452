import { EnvelopeIcon } from '@heroicons/vue/20/solid';
<template>
  <div class="">
    <li
      v-show="$store.state.support?.managerLoading"
      class="relative bg-gray-50 rounded border border-gray-200 p-5 block mb-3 w-full"
    >
      <div class="animate-pulse flex">
        <div
          class="block w-20 h-20 flex-none mr-3 rounded-full bg-gray-100 border border-gray-200"
        ></div>
        <div class="w-full pt-2">
          <span class="h-4 rounded bg-gray-300 block w-40 mb-3"></span>
          <span class="h-4 rounded bg-gray-200 block w-48 mb-1"></span>
          <span class="h-4 rounded bg-gray-200 block w-32"></span>
        </div>
      </div>
    </li>
    <div
      v-show="!$store.state.support?.managerLoading"
      class="relative bg-gray-50 rounded border border-gray-200 p-5 block flex mb-3 items-center"
    >
      <img
        v-if="$store.state.support?.manager.image"
        :src="$store.state.support?.manager.image"
        :alt="'photo of ' + $store.state.support?.manager.name"
        class="w-20 h-20 flex-none mr-3 rounded-full border border-gray-200"
      />
      <div
        v-if="!$store.state.support?.manager.image"
        class="w-20 h-20 flex-none mr-3 rounded-full bg-gray-100 border border-gray-200 flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 49.3 62.6"
          class="w-10 h-10"
        >
          <g>
            <path
              fill="#7692C1"
              d="M13,3.7c-4.9,0-8.9,4-8.9,8.9s4,8.9,8.9,8.9h8.9v-8.9C21.9,7.6,17.9,3.7,13,3.7"
            />
            <path
              fill="#7692C1"
              d="M37.4,0c-5.9,0-10.7,4.8-10.7,10.7v10.7h10.7c5.9,0,10.7-4.8,10.7-10.7S43.3,0,37.4,0"
            />
            <rect fill="#7692C1" x="18.5" y="27.8" width="12.3" height="34.6" />
            <rect fill="#DC4C7C" x="37" y="27.8" width="12.3" height="34.6" />
            <rect fill="#DC4C7C" y="27.8" width="12.3" height="34.6" />
          </g>
        </svg>
      </div>
      <div class="">
        <h3 class="text-sm font-bold text-gray-900 mb-3 leading-none">
          {{ $store.state.support?.manager.name }}
        </h3>
        <ul>
          <li>
            <a
              :href="'mailto:' + $store.state.support?.manager.email"
              class="flex items-center hover:text-gray-600 transition ease-in-out duration-300 pb-1"
            >
              <EnvelopeIcon class="w-4 h-4 mr-1" :class="{ 'text-pink-300': !themeEnabled }" />
              <span class="text-sm leading-none">{{ $store.state.support?.manager.email }}</span>
            </a>
          </li>
          <li>
            <a
              :href="'tel:' + $store.state.support?.manager.phone"
              class="flex items-center hover:text-gray-600 transition ease-in-out duration-300 pt-1"
            >
              <PhoneIcon class="w-4 h-4 mr-1" :class="{ 'text-pink-300': !themeEnabled }" />
              <span class="text-sm leading-none">{{ $store.state.support?.manager.phone }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { EnvelopeIcon, PhoneIcon } from "@heroicons/vue/24/outline"
import { usePageProps } from "@/composables/pageProps"

const { themeEnabled } = usePageProps()
</script>
