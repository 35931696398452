<template>
  <li class="">
    <a
      :href="article.html_url"
      target="_blank"
      rel="noreferrer"
      class="group relative bg-white rounded border border-blue-200 p-6 block mb-3 hover:border-primary transition ease-in-out duration-300 focus:outline-none focus:border-primary"
    >
      <h3 class="font-title font-bold leading-tight mb-1">{{ article.title }}</h3>
      <p class="text-sm">{{ description }}</p>
      <span
        class="absolute top-1 right-1 text-gray-200 group-hover:text-primary transition-colors ease-in-out"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
          />
          <path
            d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
          />
        </svg>
      </span>
    </a>
  </li>
</template>

<script>
import { striptags, truncate } from "@/helpers/string-helpers"

export default {
  props: {
    article: {
      type: Object,
      require: true,
    },
  },
  computed: {
    description() {
      if (this.article.snippet) return this.article.snippet

      let strippedText = striptags(this.article.body)
      return truncate(strippedText, 75)
    },
  },
}
</script>
