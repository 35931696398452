<template>
  <div v-if="support" class="">
    <ul v-show="support.articlesLoading">
      <li
        v-for="i in 3"
        :key="i"
        class="relative bg-white rounded border border-blue-200 p-6 block mb-3 w-full"
      >
        <div class="animate-pulse">
          <span class="h-4 rounded bg-gray-300 block w-full mb-1"></span>
          <span class="h-4 rounded bg-gray-300 block w-1/4 mb-2"></span>
          <span class="h-4 rounded bg-gray-200 block w-full mb-1"></span>
          <span class="h-4 rounded bg-gray-200 block w-1/2"></span>
        </div>
      </li>
    </ul>
    <ul v-show="!support.articlesLoading">
      <support-articles-item
        v-for="article in support.articles"
        :key="article.id"
        :article="article"
      ></support-articles-item>
    </ul>
    <nav v-show="support.articlesPagination.page_count > 1">
      <ul class="pagination flex items-center text-gray-600 justify-start">
        <li
          v-for="n in support.articlesPagination.page_count"
          :key="n"
          :class="{
            'page-item active': n == support.articlesPagination.current_page,
          }"
        >
          <span
            v-show="n == support.articlesPagination.current_page"
            class="page-link block px-4 py-2 rounded bg-primary text-primary-on border border-blue-100 mx-1 font-bold"
          >
            {{ n }}
          </span>
          <button
            v-show="n != support.articlesPagination.current_page"
            class="page-link block px-4 py-2 rounded bg-white border border-blue-100 mx-1 focus:border-primary hover:border-primary"
            type="button"
            @click="goToPage(n)"
          >
            <span v-show="!support.pageLoading || page != n">{{ n }}</span>
            <span v-show="support.pageLoading && page == n">
              <svg
                class="animate-spin h-5 w-5 -mx-2 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          </button>
        </li>
      </ul>
    </nav>
    <div
      v-show="
        ((support.articles && support.articles.length == 0) || support.articles == null) &&
        !support.articlesLoading
      "
    >
      <p>{{ $t("support.no-articles") }}</p>
    </div>
  </div>
</template>

<script>
import SupportArticlesItem from "./SupportArticlesItem.vue"
export default {
  components: {
    SupportArticlesItem,
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    support() {
      return this.$store?.state?.support
    },
  },
  methods: {
    goToPage(page) {
      this.page = page
      if (this.$store.state.support.loadAll && this.page != 1) {
        this.$store.dispatch("support/loadAllArticles", {
          currentRoute: this.currentRoute,
          page: this.page,
        })
      } else {
        this.$store.dispatch("support/loadRouteArticles", {
          currentRoute: this.currentRoute,
          page: this.page,
        })
      }
    },
  },
}
</script>
